<template>

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Asociación de Abogados</title>
</head>
<body>
    <header>
        <h1>Bienvenido a la Asociación de Abogados</h1>
    </header>
    <nav>
        <ul>
            <li><a href="#about">Acerca de nosotros</a></li>
            <li><a href="#services">Nuestros Servicios</a></li>
            <li><a href="#team">Nuestro Equipo</a></li>
            <li><a href="#contact">Contacto</a></li>
        </ul>
    </nav>
    <section id="about">
        <h2>Acerca de nosotros</h2>
        <p>La Asociación de Abogados es un grupo dedicado a proporcionar servicios legales excepcionales a nuestra comunidad. Nos especializamos en diversas áreas del derecho y estamos comprometidos con la excelencia y la justicia.</p>
    </section>
    <section id="services">
        <h2>Nuestros Servicios</h2>
        <ul>
            <li><strong>Derecho Civil:</strong> Representación en casos civiles, contratos, y litigios.</li>
            <li><strong>Derecho Penal:</strong> Defensa en casos penales, derechos del acusado.</li>
            <li><strong>Derecho de Familia:</strong> Divorcios, custodia de hijos, adopciones.</li>
            <!-- Agrega más servicios según sea necesario -->
        </ul>
    </section>
    <section id="team">
        <h2>Nuestro Equipo</h2>
        <div class="team-member">
            <img src="abogado1.png" alt="Abogado 1">
            <p><strong>Nombre del Abogado 1</strong><br>Experto en Derecho Penal</p>
        </div>
        <div class="team-member">
            <img src="abogado2.png" alt="Abogado 2">
            <p><strong>Nombre del Abogado 2</strong><br>Especialista en Derecho de Familia</p>
        </div>
        <!-- Agrega más miembros del equipo según sea necesario -->
    </section>
    <section id="contact">
        <h2>Contacto</h2>
        <p>Estamos aquí para ayudarte. Si tienes alguna pregunta o necesitas asesoramiento legal, no dudes en contactarnos.</p>
        <p>Email: <a href="mailto:info@abogados.com">info@abogados.com</a></p>
    </section>
    <footer>
        <p>&copy; 2024 Asociación de Abogados</p>
    </footer>
</body>
</template>

<script>

export default {
  name: 'App',
  components: {
    
  }
}
</script>

<style>
@import "assets/css/main.css";

</style>
